import React from 'react';
import '../components/AboutInfo.css';
import about from '../images/aboutinfo.jpg';

const AboutInfo = () => {
    return (
        <React.Fragment>
            <section className="aboutInfo">
                <div className="container-fluid">
                    <div className="row ">
                        <div className="col-lg-6 col-12 padzero">
                            <div className="aboutInfoImage">
                                <img src={about} alt="about" />
                            </div>
                        </div>
                        <div className="col-lg-6 col-12 padzero">
                            <div className="aboutInfoText">
                                <h2>About Us</h2>
                                <p>Sri Saara elevators founded in Salem, Tamilnadu has become one of the largest elevator company in the country. We are industry leaders and are able to handle any kind of construction, renovation and maintenance. We are at the forefront of providing state of the art new technologies to our clients, and determined commitment to complete customer satisfaction.</p>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </section>
        </React.Fragment>
    )
}

export default AboutInfo;
