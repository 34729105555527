import React from 'react';
import '../components/Whyus.css';
import HighQualityIcon from '@material-ui/icons/HighQuality';
import AlarmIcon from '@material-ui/icons/Alarm';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';

const Whyus = () => {
    return (
        <React.Fragment>
            <section className="whyus ptb_100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 offset-lg-2 col-12">
                            <div className="section-title text-center">
                                <h2>Why Us</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 col-12">
                            <div className="single_why">
                                <HighQualityIcon className="icon" />
                                <h4>Premium Quality Services</h4>
                                <p>We are at the forefront of providing state of the art new technologies to our clients, and determined commitment to complete customer satisfaction.</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-12">
                            <div className="single_why">
                                <AlarmIcon className="icon"/>
                                <h4>Ontime Delivery</h4>
                                <p>We have a committed team of trained technicians in important cities, to give you prompt service support in an emergency or an unlikely malfunction.</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-12">
                            <div className="single_why">
                                <HourglassEmptyIcon className="icon"/>
                                <h4>Installation Support</h4>
                                <p>We provide complete installation support for every service undertaken.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    )
}

export default Whyus;
