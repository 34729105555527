import React from 'react';
import '../components/ProductInfo.css';
import passengerElevator from '../images/Passenger_Elevator.jpg';
import BeenhereIcon from '@material-ui/icons/Beenhere';
import hospitalElevator from '../images/Hospital_Elevator.jpg';
import goodsElevator from '../images/Goods_Elevator.jpg';
import hydraulicElevator1 from '../images/Hydraulic_Elevator1.jpeg';
import mrlElevator from '../images/Mrl_Elevator2.jpg';
import hydraulicElevator2 from '../images/Hydraulic_Elevator2.jpg';
import homeElevator1 from '../images/Home_Elevator1.jpg';
import homeElevator2 from '../images/Home_Elevator2.jpg';
import cabin1 from '../images/MildSteel_Cabin.jpg';
import cabin2 from '../images/Stainless_Cabin.jpg';
import cabin3 from '../images/Panaromic_Cabin.jpg';
import cabin4 from '../images/Capsule_Cabin.jpg';
import doors1 from '../images/Doors1.jpg';
import doors2 from '../images/Doors2.jpg';
import doors3 from '../images/Doors3.jpg';
import doors4 from '../images/Doors4.jpg';

const ProductInfo = () => {
    return (
        <React.Fragment>
            <section className="productInfo">
                <div className="container-fluid">
                    {/* Passenger Elevator */}
                    <div className="row">
                        <div className="col-lg-6 col-12 padzero">
                            <div className="passengerImage">
                                <img src={passengerElevator} alt="passenger_elevator" />
                            </div>
                        </div>
                        <div className="col-lg-6 col-12 padzero">
                            <div className="passengerText">
                                <h2>PASSENGER ELEVATOR</h2>
                                <div className="row">
                                    <div className="col-lg-6 col-12">
                                    <ul>
                                    <li><BeenhereIcon className="passengerTextIcon" />Adjustable gate Opening & Closing Time</li>
                                    <li><BeenhereIcon className="passengerTextIcon"/>Alarm button</li>
                                    <li><BeenhereIcon className="passengerTextIcon"/>Car Call Cancellation at Terminal Stop</li>
                                    <li><BeenhereIcon className="passengerTextIcon"/>Attendant</li>
                                    <li><BeenhereIcon className="passengerTextIcon"/>Direction Arrow and Position Indicator considering mention to all Stops</li>
                                    <li><BeenhereIcon className="passengerTextIcon"/>Home landing</li>
                                    <li><BeenhereIcon className="passengerTextIcon"/>Phase Failure and phase Reversal auspices</li>
                                    <li><BeenhereIcon className="passengerTextIcon"/>Repeated Door Closing in the have an effect on of lock failure </li>
                                    
                                </ul>
                                    </div>
                                    <div className="col-lg-6 col-12">
                                        <ul>
                                        <li><BeenhereIcon className="passengerTextIcon"/>Door Protection by Light Curtain Full length 94 beams</li>
                                    <li><BeenhereIcon className="passengerTextIcon"/>Emergency Lighting</li>
                                    <li><BeenhereIcon className="passengerTextIcon"/>Intercom Car to Machine Room </li>
                                    <li><BeenhereIcon className="passengerTextIcon"/>Overload Indicator</li>
                                    <li><BeenhereIcon className="passengerTextIcon"/>Automatic Rescue Device</li>
                                    <li><BeenhereIcon className="passengerTextIcon"/>Compulsory Stop</li>
                                    <li><BeenhereIcon className="passengerTextIcon"/>Duplex, Triplex, Quadruplex Control</li>
                                    <li><BeenhereIcon className="passengerTextIcon"/>Direction Pre-announcing as soon as Arrival Going</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                    {/* HOSPITAL ELEVATOR */}
                    <div className="row">
                        <div className="col-lg-6 col-12 padzero">
                            <div className="hospitalText">
                                <h2>HOSPITAL ELEVATOR</h2>
                                <p>Reliable elevator designed especially for hospitals, with a solid set of features for optimum performance in the highly demanding environment of patient transportation. Quiet operation to prevent scuffle to patients in wards adjoining the elevator shaft and precise leveling for easy admittance of wheelchairs, beds, and patients</p>
                            </div>
                        </div>
                        <div className="col-lg-6 col-12 padzero">
                            <div className="hospitalImage">
                                <img src={hospitalElevator} alt="hospital_elevator" />
                            </div>
                        </div>
                    </div>
                    {/* GOODS ELEVATOR */}
                    <div className="row">
                        <div className="col-lg-6 col-12 padzero">
                            <div className="goodsImage">
                                <img src={goodsElevator} alt="goods_elevator" />
                            </div>
                        </div>
                        <div className="col-lg-6 col-12 padzero">
                            <div className="goodsText">
                                <h2>GOODS ELEVATOR</h2>
                                <p>We are engaged in presenting a broad array of Fright Elevators that helps in transferring omnipotent quantity from one level to unconventional. These goods elevators are extensively used in factories and storage facilities for their high readiness and serene operations. Designed as per the industry standards.</p>
                            </div>
                        </div>
                    </div>
                    {/* MRL ELEVATOR */}
                    <div className="row">
                        <div className="col-lg-6 col-12 padzero">
                            <div className="mrlText">
                                <h2>MRL ELEVATOR (Machine Roomless Elevator)</h2>
                                <p>The elevator is just subsequent to the eyes of the architecture. It adequately shows construction characteristics. Brings you a lawless concept in the arena of elevators to have the funds for you unmodified reprieve in designing MRL Machine Room Less Elevators. Our Exclusive Machine room-less elevators bring aesthetic enhancements to your structure.</p>
                            </div>
                        </div>
                        <div className="col-lg-6 col-12 padzero">
                            <div className="mrlImage">
                                <img src={mrlElevator} alt="mrl_elevator" />
                            </div>
                        </div>
                    </div>
                    {/* HYDRAULIC ELEVATOR */}
                    <div className="row">
                        <div className="col-lg-3 col-12 padzero">
                            <div className="hydraulicImage">
                                <img src={hydraulicElevator1} alt="hydraulic_elevator1" />
                            </div>
                        </div>
                        <div className="col-lg-3 col-12 padzero">
                            <div className="hydraulicImage">
                                <img src={hydraulicElevator2} alt="hydraulic_elevator2" />
                            </div>
                        </div>
                        <div className="col-lg-6 col-12 padzero">
                            <div className="hydraulicText">
                                <h2>HYDRAULIC ELEVATOR</h2>
                                <p>Hydraulic elevators are powered by hydraulic jack, which are fluid-driven pistons that travel inside of a cylinder. Advantages of Hydraulic elevator are:</p>
                                <ul>
                                    <li><BeenhereIcon className="hydraulicTextIcon" />Quicker to install than other elevator types</li>
                                    <li><BeenhereIcon className="hydraulicTextIcon"/>Less expensive to install and maintain.</li>
                                    <li><BeenhereIcon className="hydraulicTextIcon"/>Ideal for transporting heavy loads</li>
                                    <li><BeenhereIcon className="hydraulicTextIcon"/>Occupy less space in a building</li>
                                    <li><BeenhereIcon className="hydraulicTextIcon"/>Option to have machine room-less configuration</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                     {/* HOME ELEVATOR */}
                     <div className="row">
                        <div className="col-lg-6 col-12 padzero">
                            <div className="homeText">
                                <h2>HOME ELEVATOR</h2>
                                <p>Home elevators provide assistance to those with limited mobility, create ease of access between floors, and serve as a design element to enhance the beauty of the home. We custom-designs and builds each residential elevator it installs to meet your specific requirements.</p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-12 padzero">
                            <div className="homeImage">
                                <img src={homeElevator1} alt="home_elevator1" />
                            </div>
                        </div>
                        <div className="col-lg-3 col-12 padzero">
                            <div className="homeImage">
                                <img src={homeElevator2} alt="home_elevator2" />
                            </div>
                        </div>
                    </div>
                    {/* CABINS */}
                    <div className="row">
                        <div className="col-lg-3 col-12 padzero">
                            <div className="cabinImage">
                                <img src={cabin1} alt="cabin1" />
                            </div>
                            <div className="cabinImage">
                                <img src={cabin2} alt="cabin2" />
                            </div>
                        </div>
                        <div className="col-lg-3 col-12 padzero">
                            <div className="cabinImage">
                                <img src={cabin3} alt="cabin3" />
                            </div>
                            <div className="cabinImage">
                                <img src={cabin4} alt="cabin4" />
                            </div>
                        </div>
                        <div className="col-lg-6 col-12 padzero">
                            <div className="cabinText">
                                <h2>CABINS</h2>
                                <p>We produce elevators taking into account various types of cabins to lawsuit the taste of all client. We have a range which starts from basic Mild steel powder coated cabins to premium designer stainless steel cabins.</p>
                                <h4>Types of Cabins</h4>
                                <ul>
                                    <li><BeenhereIcon className="cabinTextIcon"/>Mild steel cabins (M.S)</li>
                                    <li><BeenhereIcon className="cabinTextIcon"/>Stainless Steel Cabins (S.S)</li>
                                    <li><BeenhereIcon className="cabinTextIcon"/>Designed S.S. Cabins.</li>
                                    <li><BeenhereIcon className="cabinTextIcon"/>Panoromic Cabin.</li>
                                    <li><BeenhereIcon className="cabinTextIcon"/>Capsule Cabin.</li>
                                    <li><BeenhereIcon className="cabinTextIcon"/>Luxury Cabin.</li>
                                </ul>
                            </div>
                        </div>  
                    </div>
                    {/* DOORS */}
                    <div className="row">
                        <div className="col-lg-6 col-12 padzero">
                            <div className="doorsText">
                                <h2>Doors</h2>
                                <p>Center Opening, Telescopic, Collapsible, IFG, Manual Swing and Designer types of Manual and Automatic operated doors are fitted with world-class operating mechanisms which are very robust to the continuous wear and tear.</p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-12 padzero">
                            <div className="doorsImage">
                                <img src={doors1} alt="doors1" />
                            </div>
                            <div className="doorsImage">
                                <img src={doors2} alt="doors2" />
                            </div>
                        </div>
                        <div className="col-lg-3 col-12 padzero">
                            <div className="doorsImage">
                                <img src={doors3} alt="doors3" />
                            </div>
                            <div className="doorsImage">
                                <img src={doors4} alt="doors4" />
                            </div>
                        </div>  
                    </div>
                </div>
            </section>
        </React.Fragment>
    )
}

export default ProductInfo;
