import React from 'react';
import ContactInfo from '../components/ContactInfo';
import '../pages/Contact.css';

const Contact = () => {
    return (
        <React.Fragment>
            <div className="contact_banner">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="banner_info">
                                {/* <ul>
                                    <li><Link><HomeIcon className="banner_infoIcon"/>Home</Link></li>
                                    <li><Link><KeyboardArrowRightIcon className="banner_infoIcon"/>About</Link></li>
                                </ul> */}
                                <h1>Contact Us</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ContactInfo />
        </React.Fragment>
    )
}

export default Contact;
