import React, { useState } from 'react';
import '../components/Navbar.css';
import {Link} from 'react-router-dom';
import logo from '../images/logo150_100_light.png';
import PhoneIcon from '@material-ui/icons/Phone';
import MailIcon from '@material-ui/icons/Mail';

const Navbar = () => {

    const [click, setClick] = useState(false);

    const handleClick = () => setClick(!click);

    const closeMobileMenu = () => setClick(false);

    return (
        <React.Fragment>
            <nav className="navbar">
                <div className="container">
                    <Link to="/" className="navbar-logo">
                        <img src={logo} alt="saaralogo" />
                    </Link>
                    <div className="menu-icon" onClick={handleClick} >
                        <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
                    </div>
                    <ul className={click ? 'nav-menu active' : 'nav-menu'}>
                        <li className="nav-item" onClick={closeMobileMenu} >
                            <Link to="/" className="nav-link">Home</Link>
                        </li>
                        <li className="nav-item" onClick={closeMobileMenu} >
                            <Link to="/about" className="nav-link">About</Link>
                        </li>
                        <li className="nav-item" onClick={closeMobileMenu}>
                            <Link to="/products" className="nav-link">Products</Link>
                        </li>
                        <li className="nav-item" onClick={closeMobileMenu}>
                            <Link to="/contact" className="nav-link">Contact</Link>
                        </li>
                    </ul>
                    <div className="header_right">
                        <ul className="header_rightMenu">
                            <li><a href="//#endregion"><PhoneIcon className="phoneIcon" />+91 96005 17109</a></li>
                            <li><a href="//#endregion"><MailIcon className="mailIcon" />srisaraaelevators@gmail.com</a></li>
                        </ul>
                    </div>
                </div>
            </nav>
        </React.Fragment>
    )
}

export default Navbar;
