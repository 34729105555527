import React from 'react';
import ProductInfo from '../components/ProductInfo';
import '../pages/Products.css';

const Products = () => {
    return (
        <React.Fragment>
            <div className="products_banner">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="banner_info">
                                {/* <ul>
                                    <li><Link><HomeIcon className="banner_infoIcon"/>Home</Link></li>
                                    <li><Link><KeyboardArrowRightIcon className="banner_infoIcon"/>About</Link></li>
                                </ul> */}
                                <h1>Our Products</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ProductInfo />
        </React.Fragment>
    )
}

export default Products;
