import React from 'react';
import '../components/Welcome.css';
import welcome from '../images/welcome.jpg';

const Welcome = () => {
    return (
        <React.Fragment>
            <section className="welcome ptb_100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-12">
                            <div className="welcomeText">
                                <h4>Welcome to the Sri Saraa Elevators!</h4>
                                <h2>Lifting Ideas.</h2>
                                <p>Ideas are answerable for the progress and wealth of humans without them, we would still be vibrant in the epoch. No idea is too little, and all sorts of ideas have the potential to alter the world as we know it for the augmented.., <strong>your idea could fiddle in the tune of the world for the greater than before</strong>.</p>
                            </div>
                        </div>
                        <div className="col-lg-6 col-12">
                            <div className="welcomeImage">
                                <img src={welcome} alt="welcome" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    )
}

export default Welcome;
