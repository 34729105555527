import React from 'react';
import AboutInfo from '../components/AboutInfo';
import Whyus from '../components/Whyus';
import '../pages/About.css';

const About = () => {
    return (
        <React.Fragment>
            <div className="about_banner">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="banner_info">
                                {/* <ul>
                                    <li><Link><HomeIcon className="banner_infoIcon"/>Home</Link></li>
                                    <li><Link><KeyboardArrowRightIcon className="banner_infoIcon"/>About</Link></li>
                                </ul> */}
                                <h1>About Us</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <AboutInfo />
            <Whyus />
        </React.Fragment>
    )
}

export default About;
