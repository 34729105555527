import React from 'react';
import '../components/Footer.css';
import PhoneIcon from '@material-ui/icons/Phone';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import LocationOnIcon from '@material-ui/icons/LocationOn';

const Footer = () => {
    return (
        <React.Fragment>
            <section className="footer ptb_50">
                <div className="footer_top">
                    <div className="container">

                        <div className="row">
                            <div className="col-lg-6 col-12">
                                <div className="single_footer">
                                    <h2>About Sri Saraa Elevators</h2>
                                    <p>Sri Saara elevators founded in Salem, Tamilnadu has become one of the largest elevator company in the country. We are industry leaders and are able to handle any kind of construction, renovation and maintenance. We are at the forefront of providing state of the art new technologies to our clients, and determined commitment to complete customer satisfaction.</p>
                                </div>
                            </div>
                            <div className="col-lg-6 col-12">
                                <div className="single_footer">
                                    <h2>Office Location</h2>
                                    <div className="single_footerContact">
                                        <LocationOnIcon className="icon" />
                                        <p>#10 A1, Dr. Sankaran Road, Near State Bank, <br />Trichy Main Road, Namakkal - 637 001.</p>
                                    </div>
                                    <div className="single_footerContact">
                                        <PhoneIcon className="icon" />
                                        <p>+91 96005 17109 , +91 96262 62800</p>
                                    </div>
                                    <div className="single_footerContact">
                                        <MailOutlineIcon className="icon" />
                                        <p>srisaraaelevators@gmail.com</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer_bottom">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-12">
                                <div className="copyrightText">
                                    <p>Copyright &copy; 2021. All Rights Reserved. Powered by <a href="https://trumpetservices.com/" target="_blank" rel="noreferrer">Trumpet Media Services.</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    )
}

export default Footer;
