import React from 'react';
import '../components/ContactInfo.css';
import PhoneIcon from '@material-ui/icons/Phone';
import MailIcon from '@material-ui/icons/Mail';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import {Button} from '@material-ui/core';
import emailjs from 'emailjs-com';

const ContactInfo = () => {
      
    function sendEmail(e) {
        e.preventDefault();
    
        emailjs.sendForm('gmail', 'template_zuamewd', e.target, 'user_2HChEP5ja0iRMhpN5SqhA')
          .then((result) => {
              console.log(result.text);
          }, (error) => {
              console.log(error.text);
          });
          e.target.reset()
      }

    return (
        <React.Fragment>
            <section className="contact_info">
                <div className="container">
                    
                    <div className="row">
                        <div className="col-lg-8 offset-lg-2 text-center">
                            <div className="contact_infoTitle">
                                <h2>“Get in Touch”</h2>
                                <p>We would like to hear from you. If you have any questions or feedback kindly fill the form, we will respond quickly.</p>
                            </div>
                        </div>
                    </div>

                    <div className="row Row_two">
                        <div className="col-lg-5 col-12">
                                <div className="single_address">
                                    <PhoneIcon className="single_addressIcon" />
                                    <div className="single_addressInfo">
                                        <h4>Phone</h4>
                                        <p>+91 96005 17109</p>
                                        <p>+91 96262 62800</p>
                                    </div>
                                </div>
                                <div className="single_address">
                                    <MailIcon className="single_addressIcon"/>
                                    <div className="single_addressInfo">
                                        <h4>Email</h4>
                                        <p>srisaraaelevators@gmail.com</p>
                                    </div>
                                </div>
                                <div className="single_address">
                                    <LocationOnIcon className="single_addressIcon"/>
                                    <div className="single_addressInfo">
                                        <h4>Location</h4>
                                        <p>#10 A1, Dr. Sankaran Road, Near State Bank, <br />Trichy Main Road, Namakkal - 637 001.
                                        </p>
                                    </div>
                                </div>
                        </div>
                        <div className="col-lg-7 col-12">
                            <div className="form_wrapper">
                                <form onSubmit={sendEmail}>
                                    <label htmlFor="name">
                                        <input 
                                            type="text"
                                            placeholder="Name*"
                                            name="name"
                                            required
                                        />
                                    </label>
                                    <label htmlFor="email">
                                        <input 
                                            type="text"
                                            placeholder="Email*"
                                            name="email"
                                            required
                                        />
                                    </label>
                                    <label htmlFor="phone">
                                        <input 
                                            type="text"
                                            placeholder="Phone*"
                                            name="phone"
                                            required
                                        />
                                    </label>
                                    <label htmlFor="message">
                                        <textarea 
                                            placeholder="Your Message"
                                            name="message"
                                            required
                                        />
                                    </label>
                                    {/* <div className="form_wrapperMsg">
                                        Message Sent. Our executive will respond you shortly.
                                    </div> */}
                                    <Button 
                                        variant="outlined" 
                                        type="submit"
                                        name="submit"
                                        value="send message"
                                    >
                                        Send Message
                                    </Button>
                                </form>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <div className="map_area">
                                <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15654.46698886303!2d78.1708885!3d11.2159661!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x3a7f1d73cb1e47f0!2sSri%20Saraa%20Elevators!5e0!3m2!1sen!2sin!4v1609831309079!5m2!1sen!2sin" width="100%" height="450" title="SaraaElevators" frameborder="0" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
        </React.Fragment>
    )
}

export default ContactInfo;
