import React from 'react';
import ScrollUpButton from 'react-scroll-up-button';

const ScrollUp = () => {
    return (
        <React.Fragment>
            <ScrollUpButton 
                style={{
                    backgroundColor: "#ff5e14",
                    width: 40,
                    height: 40,
                    padding: 8,
                    borderRadius: 50
                }}
            />
        </React.Fragment>
    )
}

export default ScrollUp;
