import React from 'react';
import './App.css';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import Navbar from './components/Navbar';
import Home from './pages/Home';
import About from './pages/About';
import Products from './pages/Products';
import Contact from './pages/Contact';
import Footer from './components/Footer';
import ScrollUp from './components/ScrollUp';
 
function App() {
  return (
    <React.Fragment>
        <Router>
            <Navbar />
            <Switch>
              <Route exact path="/" component={Home} />
              <Route exact path="/about" component={About}  />
              <Route exact path="/products" component={Products} />
              <Route exact path="/contact" component={Contact} />
            </Switch>
            <ScrollUp />
            <Footer />
        </Router>
    </React.Fragment>
  );
}

export default App;
