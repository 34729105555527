import React from 'react';
import PropTypes from 'prop-types';
import OwlCarousel from 'react-owl-carousel3';
import '../components/Services.css';

const Services = (props) => {
    // Start Services Loop
    const services = props.servicesData.map((service, index) => (
        <div className="col-lg-12 col-md-12 col-sm-12" key={index}> 
            <div className="single-service">
                <h2>{service.title}</h2>
                <p>{service.content}</p>
            </div>
        </div>
    ));
    // End Services Loop
    return (
        <React.Fragment>
            <section className="services ptb_100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-12">
                            <div className="section-title">
                                <h2>OUR <span>SERVICES</span></h2>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <OwlCarousel 
                            className="test-slides owl-theme"
                            loop={true}
                            nav={false}
                            dots={false}
                            autoplay={true}
                            autoplayHoverPause={true}
                            responsiveClass ={true}
                            mouseDrag={true}
                            margin={30}
                            navText={[
                                "<i className='icon icon-Arrow'></i>",
                                "<i className='icon icon-Arrow'></i>"
                            ]}
                            responsive={{
                                0: {
                                    items: 1,
                                },
                                768: {
                                    items: 2,
                                },
                                1200: {
                                    items: 3,
                                }
                            }}
                        >
                            {services}
                        </OwlCarousel>
                    </div>
                </div>
            </section>
        </React.Fragment>
    )
}


Services.propTypes = {
    title: PropTypes.string,
    content: PropTypes.string,
    servicesData: PropTypes.array
}

Services.defaultProps = {
    servicesData: [
       {
            title: "Elevator Services",
            content: "Our elevators are fully customized to meet the customers need and highest standards of quality and provide a lifetime of dependable service."
       },
       {
            title: "Lift Installation",
            content: "We have a committed team of trained technicians in important cities, to give you prompt service support in an emergency or an unlikely malfunction."
       },
       {
            title: "Cabins Elevators",
            content: "We produce elevators taking into account various types of cabins to lawsuit the taste of all client. We have a range witch starts from basic Mild steel powder coated cabins to premium designer stainless steel cabins."
       },
    ]
}
export default Services;
