import React from 'react';
import HomeSlider from '../components/HomeSlider';
import Services from '../components/Services';
import Welcome from '../components/Welcome';
import Whyus from '../components/Whyus';

const Home = () => {
    return (
        <React.Fragment>
            <HomeSlider />
            <Welcome />
            <Services />
            <Whyus />
        </React.Fragment>
    )
    
}

export default Home;
