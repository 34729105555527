import React, { Component } from 'react';
import '../components/HomeSlider.css';
import OwlCarousel from 'react-owl-carousel3';
import VisibilitySensor from 'react-visibility-sensor';
import PropTypes from 'prop-types';
import i from 'react-icofont';

const options = {
    items: 1,
    nav: true,
    dots: false,
    loop: true,
    autoplay: true,
    autoplayHoverPause: true,
    touchDrag: false,
    mouseDrag: true,
    responsiveClass: true,
}

class HomeSlider extends Component {
    render() {
        return (
            <React.Fragment>
                <OwlCarousel 
                    id="home"
                    className="home-slides owl-theme"
                    navText={[
                        "<i class='icofont-swoosh-right'></i>",
                        "<i class='icofont-swoosh-right'></i>"
                    ]}
                    {...options}
                >
                    {this.props.slideData.map((data, index) => (
                        <div className={`main-banner ${data.klasName}`} key={index}>
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-lg-12 col-md-12">
                                                <VisibilitySensor >
                                                    {({isVisible}) => (
                                                        <div className="main-banner-text">
                                                            <h1 className={isVisible ? "animated fadeInDown slow opacityone" : ""}>
                                                                {data.headline}
                                                            </h1>
                                                            <p className={isVisible ? "animated fadeInDown slow opacityone" : ""}>
                                                                {data.subHeadline}
                                                            </p>
                                                        </div>
                                                    )}
                                                </VisibilitySensor>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                    ))}
                </OwlCarousel>
            </React.Fragment>
        )
    }
}

HomeSlider.propTypes = {
    slideData: PropTypes.array
}

HomeSlider.defaultProps = {
    slideData: [
        {
            headline: "Made for Safety",
            subHeadline: "Our elevators are fully customized to meet the customers need and highest standards of quality and provide a lifetime of dependable service.",
            klasName: "item-bg-one"
        },
        {
            headline: 'Choose from the best',
            subHeadline: 'Our customized elevators also facilitate moving bulky objects from floor-to-floor safer, easier and more conveniently.',
            klasName: 'item-bg-two'
        },
        {
            headline: 'Easy services for you',
            subHeadline: 'We have a committed team of trained technicians in important cities, to give you prompt service support in an emergency or an unlikely malfunction.',
            klasName: 'item-bg-three'
        },
        {
            headline: 'All your elevator needs fulfilling',
            subHeadline: 'We offer a variety of elevator cabins and doors with different colors and finishes that meet home/office aesthetics and functionality.',
            klasName: 'item-bg-four'
        }
    ]
}
export default HomeSlider;
